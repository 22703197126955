<template>
  <div class="">
    <div
      class="flex items-center justify-between"
      :class="{ 'mb-3': !isCollapsed }"
    >
      <slot name="title"></slot>
      <div class="space-x-3">
        <button
          v-if="false"
          class="text-sm font-medium text-grey-700 hover:text-black-900"
        >
          Clear
        </button>
        <button @click="toggleAccordion" class="p-1">
          <svg
            :class="{ 'transform rotate-180': isCollapsed }"
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 5L5 1L1 5"
              stroke="#484749"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
    <slot name="content" v-if="!isCollapsed"></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCollapsed: false,
    };
  },
  methods: {
    toggleAccordion() {
      return (this.isCollapsed = !this.isCollapsed);
    },
  },
};
</script>
